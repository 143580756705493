import Input from 'components/input/input'
import React, { useEffect, useState } from 'react'
import {Modal, ModalBody, ModalHeader } from 'reactstrap'
import { SubscriberService } from 'services';
import Swal from 'sweetalert2'

const PasswordSubscriberModal = ({ isOpen, onClose, subscriber }) => {
  const [errors, setErrors] = useState()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  
  useEffect(() => {
    if (isOpen) {
      setPassword('')
      setConfirmPassword('')
    }
  }, [isOpen])

  const submitHandler = () => {
    let msgErrors = '';

    if (!password || !confirmPassword) {
      msgErrors = 'Los campos son obligatorios';
    }

    if (password !== confirmPassword) {
      msgErrors = 'Debe ingresar la misma contraseña en ambos campos';
    }

    if (msgErrors) {
      setErrors(msgErrors)
    } else {
      setErrors('')
      saveHandler()
    }
  }

  const saveHandler = async() => {
    try {
      await SubscriberService.updatePassword(subscriber.username, password);
      setPassword('')
      setConfirmPassword('')
      Swal.fire({
        title: 'Contraseña actualizada',
        text: 'La contraseña del usuario ha sido actualizada',
        icon: 'success'
      })
      onClose()
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error al actuaizar contraseña',
        icon: 'error',
      })
      console.log(error)
    }
  }

  const changePasswordHandle = (e) => {
    setPassword(e.target.value)
  }

  const changeConfirmHandle = (e) => {
    setConfirmPassword(e.target.value)
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader>Cambiar contraseña</ModalHeader>
        <ModalBody>
          <p>Cambiar contraseña de <strong>{`${subscriber?.username}`}</strong></p>
          <br/>
          { errors && <p className="text-danger">{errors}</p> }
          <form>
            <Input
              label="Nueva contraseña"
              name='password'
              type="password"
              value={password}
              onChange={changePasswordHandle}
            />
            <Input
              label="Confirmar contraseña"
              name='confirm_password'
              type="password"
              value={confirmPassword}
              onChange={changeConfirmHandle}
            />
            
            <div className="mt-4 text-right">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Cancelar</button> 
              <button type="button" className="btn btn-primary" onClick={submitHandler}>Cambiar contraseña</button> 
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PasswordSubscriberModal