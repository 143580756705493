import Header from 'components/Headers/Header'
import React, { useEffect, useState } from 'react'
import { Badge, Button } from 'reactstrap/es'
import DataTable from 'react-data-table-component';
import SubscriberService from '../../services/subscriber.service';
import AddSuscriptorModal from './AddSuscriptorModal';
import EditSubscriberModal from './EditSubscriberModal';
import PasswordSubscriberModal from './PasswordSubscriberModal';
import moment from 'moment';
import Swal from 'sweetalert2';
import Searchbar from 'components/Searchbar/Searchbar';
import { accentFold } from '../../utilities/helper'

const SuscriptoresList = () => {
  const [suscriptores, setSuscriptores] = useState([])
  const [filterSuscriptores, setFilterSuscriptores] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [editSubscriber, setEditSubscriber] = useState({})
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [subscriberChangePass, setSubscriberChangePass] = useState(null)
  const [isChangePassOpen, setIsChangePassOpen] = useState(false)

  const columns = [
    {
      name: 'Usuario',
      selector: row => row.username,
      sortable: true
    },
    {
      name: 'Nombre',
      selector: row => row.name,
      sortable: true
    },
    {
      name: 'Correo',
      selector: row => row.email,
      sortable: true
    },
    {
      name: 'Estatus',
      width: '110px',
      selector: row => {
        return (
          <Badge color="" className="badge-dot mr-4">
            <i className={`${row.is_active ? 'bg-success' : 'bg-danger'}`} />
            { row.is_active ? `Activo` : `Inactivo` }
          </Badge>
        )
      },
    },
    {
      name: 'Fecha creación',
      width: '150px',
      selector: row => moment(row.created_at).format("DD-MM-YYYY"),
      sortable: true
    },
    {
      name: 'Acciones',
      allowOverflow: true,
      width: '160px',
      selector: row => {
        return (
          <div>
            <Button
              color="info"
              size="sm"
              title="Editar"
              onClick={() => openEditModal(row)}
            >
              <i className="far fa-edit"></i>
            </Button>
            <Button 
              color="warning"
              size="sm"
              title="Cambiar contraseña"
              onClick={() => openChangePassModal(row)}
            >
              <i className="fas fa-key"></i>
            </Button>
            <Button 
              color="danger"
              size="sm"
              title="Eliminar"
              onClick={() => confirmDelete(row)}
            >
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        )
      }
    }
  ]
  
  useEffect(() => {
    loadSuscriptores()
  }, [])
  
  const loadSuscriptores = async() => {
    try {
      setIsLoading(true)
      const res = await SubscriberService.list()
      setSuscriptores(res.data)
      setFilterSuscriptores(res.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const toggleAddModal = () => {
    setIsAddModalOpen(!isAddModalOpen)
  }

  const addHandler = () => {
    setIsAddModalOpen(false)
    loadSuscriptores()
  }

  const toggleEditModal = () => {
    setIsEditOpen(!isEditOpen)
  }

  const openEditModal = (item) => {
    setEditSubscriber(item)
    setIsEditOpen(true)
  }

  const editHandler = () => {
    setIsEditOpen(false)
    loadSuscriptores()
  }

  const confirmDelete = (account) => {
    Swal.fire({
      title: '¿Esta seguro de eliminar la cuenta "'+account.username+'"?',
      text: "No se podrá revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#D6D3D3",
      cancelButtonTextColor: "#333",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteHandler(account)
      }
    });
  }

  const deleteHandler = async (account) => {
    try {
      await SubscriberService.delete(account.username)
      Swal.fire({
        title: "Cuenta eliminada",
        text: 'La cuenta "' + account.username + '" ha sido eliminada del sitio',
        icon: "success"
      });
      loadSuscriptores()
    } catch(e) {
      Swal.fire({
        title: "Error al eliminar cuenta",
        text: 'Ocurrio un error al intentar eliminar la cuenta.',
        icon: "error"
      });
    }
  }

  const openChangePassModal = (item) => {
    setSubscriberChangePass(item)
    setIsChangePassOpen(true)
  }

  const toggleChangePassModel = () => {
    setIsChangePassOpen(!isChangePassOpen)
  }

  const searchHandle = (query) => {
    let results = suscriptores;
    console.log(query);

    if (query) {
      let strSearch = accentFold(query);
      let expSearch = new RegExp(`${strSearch}.*`, 'i');
      results = suscriptores.filter(item => {
        return expSearch.test(`${accentFold(item.username)} ${accentFold(item.name)} ${item.email}`);
      });
    }


    setFilterSuscriptores(results)
  }

  return (
    <>
      <Header title="Suscriptores" isLoading={isLoading} />
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header border-0">
                <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Listado de suscriptores</h3>
                    </div>
                    <div className="col text-right">
                      <button 
                        className="btn btn-primary btn-sm"
                        onClick={toggleAddModal}
                      >
                        Nuevo
                      </button>
                    </div>
                </div>
              </div>
              <div className="card-body px-0">
                <div className="row justify-content-end px-4">
                  <div className="col-4 mb-3">
                    <Searchbar 
                      showCancelSearch={true}
                      onChange={searchHandle}
                    />
                  </div>
                </div>
                <DataTable 
                  columns={columns}
                  data={filterSuscriptores}
                  pagination
                  keyField='_id'
                  striped
                  responsive={true}
                  defaultSortFieldId={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddSuscriptorModal 
        isOpen={isAddModalOpen}
        onClose={toggleAddModal}
        onSave={addHandler}
      />
      <EditSubscriberModal 
        isOpen={isEditOpen}
        onClose={toggleEditModal}
        onEdit={editHandler}
        editSubscriber={editSubscriber}
      />
      <PasswordSubscriberModal 
        isOpen={isChangePassOpen}
        onClose={toggleChangePassModel}
        subscriber={subscriberChangePass}
      />
    </>
  )
}

export default SuscriptoresList